import React, { Component } from "react"
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import TextField from '@material-ui/core/TextField'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import YouTube from 'react-youtube'
import FixedMenu from "../components/FixedMenu"
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import SEO from "../components/seo"

import SiteContext from "../context/SiteContext"

class Videos extends Component {

    static contextType = SiteContext;


    constructor(props) {
        super(props)
        this.state = {
            videos: [],
            allVideos: [],
            alEnlVideos: [],
            allFrVideos: [],
            allEsVideos: [],
            enVideos: [],
            frVideos: [],
            esVideos: [],
            segments: [],
            videoSegments: [],
            online: true,
            videosPageSummary: '',
            videosPageTitle: '',
            keywords: '',
            site: '',
        }
    }

    componentDidMount() {
        let site = 'senergy'

        this.context.changeSite(site)
        this.setState({ site })

        const { videoData } = this.props.pageContext
        const videos = videoData.data.craft.entries
        console.log('videos', videos)
        const enVideos = []
        const frVideos = []
        const esVideos = []

        videos.map(video => {
            if (video.videoRegion == 'us-english') {
                enVideos.push(video)
            } else if (video.videoRegion == 'us-spanish') {
                esVideos.push(video)
            } else if (video.videoRegion == 'canada-french') {
                frVideos.push(video)
            }
        })
        this.setState({
            videos,
            enVideos,
            frVideos,
            esVideos,
            allEnVideos: enVideos,
            allFrVideos: frVideos,
            allEsVideos: esVideos,
            allVideos: videos,
            site
        }, () => {
            console.log(this.state)
        })
    }

    handleKeywordsChange = keywords => {
        this.setState({
            keywords: keywords,
        }, () => {
            var enResults = this.state.allEnVideos.filter(video => {
                return (video.title.toLowerCase().indexOf(keywords.toLowerCase()) !== -1);
            })
            var frResults = this.state.allFrVideos.filter(video => {
                return (video.title.toLowerCase().indexOf(keywords.toLowerCase()) !== -1);
            })
            var esResults = this.state.allEsVideos.filter(video => {
                return (video.title.toLowerCase().indexOf(keywords.toLowerCase()) !== -1);
            })
            this.setState({ 
                enVideos: enResults,
                frVideos: frResults,
                esVideos: esResults
            })

        })
    }

    render() {
        const opts = {
            height: '260',
            width: '510',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0
            }
        }

        const regex = /(<([^>]+)>)/ig

        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <>
                        <SEO title="Videos" />
                        <div className="wrapper app-header-style-text">
                            <div className="content-wrapper">
                                <div className="app-header">
                                    <div className="app-header-content">
                                        <h1 className="app-header__title">Videos</h1>
                                        <div className="spacer"></div>
                                        <div className="contact-form__wrapper">
                                            <form onSubmit={e => { e.preventDefault(); }}>
                                                <TextField label="Search videos" variant="outlined" name="search" className={`site--senergy`} value={this.state.keywords} onChange={(event) => this.handleKeywordsChange(event.target.value)} />
                                            </form>
                                            <div className="videos__wrapper">
                                                {
                                                    this.state.enVideos.length
                                                        ? <>
                                                            <ExpansionPanel>
                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                                                    <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="videos" /></div>
                                                                    <Typography variant="h3"> English </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    {this.state.enVideos.map(video => {
                                                                        return (
                                                                            <div className="video-wrapper">
                                                                                <div className="video video-margin-bottom" key={video.id}>
                                                                                    {this.state.online ?
                                                                                        <iframe
                                                                                            width="560"
                                                                                            height="260"
                                                                                            src={`https://www.youtube.com/embed/${video.youtubeEmbedId}`}
                                                                                            srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${video.youtubeEmbedId}?autoplay=1><img src=https://img.youtube.com/vi/${video.youtubeEmbedId}/hqdefault.jpg><span>▶</span></a>`}
                                                                                            frameborder="0"
                                                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                                            allowfullscreen
                                                                                            title={video.title}
                                                                                        ></iframe>
                                                                                        : ''
                                                                                    }
                                                                                </div>
                                                                                <div className="video-details">
                                                                                    <span className="video-details__title">{video.title}</span>
                                                                                    <span className="video-details__summary">{video.summary !== null ? video.summary.replace(regex, '') : null}</span>
                                                                                    <a className="video-details__link" href={`mailto:?subject=Check%20out%20the%20video%20${video.title}%20from%20Master%20Builders%20Solutions&body=I%20found%20this%20video%20on%20the%Senergy%20app.%20Check%20it%20out: https://www.youtube.com/watch?v=${video.youtubeEmbedId}`} target="_blank" rel="noopener noreferrer">
                                                                                        <IcomoonReact className="video-details__icon" iconSet={iconSet} size={24} icon="email" />
                                                                                        Email this video
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                            <div className="spacer"></div>
                                                        </>
                                                        : null
                                                }

                                                {
                                                    this.state.frVideos.length
                                                        ? <>
                                                            <ExpansionPanel>
                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                                                    <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="videos" /></div>
                                                                    <Typography variant="h3"> French </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    {this.state.frVideos.map(video => {
                                                                        return (
                                                                            <div className="video-wrapper">
                                                                                <div className="video video-margin-bottom" key={video.id}>
                                                                                    {this.state.online ?
                                                                                        <iframe
                                                                                            width="560"
                                                                                            height="260"
                                                                                            src={`https://www.youtube.com/embed/${video.youtubeEmbedId}`}
                                                                                            srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${video.youtubeEmbedId}?autoplay=1><img src=https://img.youtube.com/vi/${video.youtubeEmbedId}/hqdefault.jpg><span>▶</span></a>`}
                                                                                            frameborder="0"
                                                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                                            allowfullscreen
                                                                                            title={video.title}
                                                                                        ></iframe>
                                                                                        : ''
                                                                                    }
                                                                                </div>
                                                                                <div className="video-details">
                                                                                    <span className="video-details__title">{video.title}</span>
                                                                                    <span className="video-details__summary">{video.summary !== null ? video.summary.replace(regex, '') : null}</span>
                                                                                    <a className="video-details__link" href={`mailto:?subject=Check%20out%20the%20video%20${video.title}%20from%20Master%20Builders%20Solutions&body=I%20found%20this%20video%20on%20the%Senergy%20app.%20Check%20it%20out: https://www.youtube.com/watch?v=${video.youtubeEmbedId}`} target="_blank" rel="noopener noreferrer">
                                                                                        <IcomoonReact className="video-details__icon" iconSet={iconSet} size={24} icon="email" />
                                                                                        Email this video
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                            <div className="spacer"></div>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    this.state.esVideos.length
                                                        ? <>
                                                            <ExpansionPanel>
                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                                                    <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="videos" /></div>
                                                                    <Typography variant="h3"> Spanish </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    {this.state.esVideos.map(video => {
                                                                        return (
                                                                            <div className="video-wrapper">
                                                                                <div className="video video-margin-bottom" key={video.id}>
                                                                                    {this.state.online ?
                                                                                        <iframe
                                                                                            width="560"
                                                                                            height="260"
                                                                                            src={`https://www.youtube.com/embed/${video.youtubeEmbedId}`}
                                                                                            srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${video.youtubeEmbedId}?autoplay=1><img src=https://img.youtube.com/vi/${video.youtubeEmbedId}/hqdefault.jpg><span>▶</span></a>`}
                                                                                            frameborder="0"
                                                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                                            allowfullscreen
                                                                                            title={video.title}
                                                                                        ></iframe>
                                                                                        : ''
                                                                                    }
                                                                                </div>
                                                                                <div className="video-details">
                                                                                    <span className="video-details__title">{video.title}</span>
                                                                                    <span className="video-details__summary">{video.summary !== null ? video.summary.replace(regex, '') : null}</span>
                                                                                    <a className="video-details__link" href={`mailto:?subject=Check%20out%20the%20video%20${video.title}%20from%20Master%20Builders%20Solutions&body=I%20found%20this%20video%20on%20the%20Senergy%20app.%20Check%20it%20out: https://www.youtube.com/watch?v=${video.youtubeEmbedId}`} target="_blank" rel="noopener noreferrer">
                                                                                        <IcomoonReact className="video-details__icon" iconSet={iconSet} size={24} icon="email" />
                                                                                        Email this video
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FixedMenu active="videos" site={siteContext.site} />
                        </div>
                    </>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default Videos